<app-header-page>
  <p class="title">Editar Formularios</p>
</app-header-page>
<div class="container pt-4">
  <form action="" class="row" [formGroup]="headerForm">
    <mat-form-field class="col-12 col-md-4">
      <mat-label>Selecione o Formulário</mat-label>
      <mat-select formControlName="field">
        <mat-option *ngFor="let form of forms" (onSelectionChange)="getFormOptions($event, form.fields)"
                    value="{{form}}">{{form.title}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<mat-divider></mat-divider>
<div class="container">
  <div class="row">
    <div class="col-12 p-4" *ngFor="let field of fields; let i = index">

      <table mat-table [dataSource]="field.content" class="mat-elevation-z6 w-100">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> {{field.title}} </th>
          <td mat-cell *matCellDef="let element">
            {{element}}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell style="text-align: end" *matHeaderCellDef>
            <div class="row p-2">
              <div class="col-10">
                <mat-form-field id="{{i}}" class="d-none" style="margin-right: 10px">
                  <mat-label>Adcione o  {{field.title}}</mat-label>
                  <input matInput #{{i}}>
                </mat-form-field>
              </div>
              <div class="col-2">
                <button mat-mini-fab (click)="toggleEdition(i)" class="col=2">
                  <mat-icon svgIcon="plus"></mat-icon>
                </button>
              </div>
            </div>

          </th>
          <td mat-cell style="text-align: end; font-size: 10px!important;" *matCellDef="let element">
            <div>
              <button mat-icon-button>
                <mat-icon style="opacity: .75;" svgIcon="xis"></mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>


      <mat-divider></mat-divider>
    </div>
  </div>
</div>
