// import 'hammerjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import  {Amplify, Auth} from 'aws-amplify';
import awsmobile from './aws-exports';


  enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


if (environment.production) {

  environment.version = 'P - 2.2'

  // PRODUCAO
  Amplify.configure(awsmobile);

  Amplify.configure({
    aws_appsync_graphqlEndpoint: 'https://ftrmnsqnpbbpdhugw7uuoho6x4.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-ot65tngrdbhebbj6ocmxpegprm',
  });

  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-2:15f2ae03-977b-4441-a8c2-bc7cfe1db261',
      region: 'us-east-2',
      userPoolId: 'us-east-2_zChqLRvOK',
      userPoolWebClientId: '2r1u7mf05k328cduu1bqpk24fb'
    },
    API: {
      graphql_headers: async () => {
        return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
      }
    }
  });

} else {

  environment.version = 'D - 2.2'

  // DESENVOLVIMENTO
  Amplify.configure(awsmobile);

  Amplify.configure({
    aws_appsync_graphqlEndpoint: 'https://ftrmnsqnpbbpdhugw7uuoho6x4.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-ot65tngrdbhebbj6ocmxpegprm',
  });

  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-2:15f2ae03-977b-4441-a8c2-bc7cfe1db261',
      region: 'us-east-2',
      userPoolId: 'us-east-2_zChqLRvOK',
      userPoolWebClientId: '2r1u7mf05k328cduu1bqpk24fb'
    },
    API: {
      graphql_headers: async () => {
        return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
      }
    }
  });
}
