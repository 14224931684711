/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listNotUpdatedSusQueue = /* GraphQL */ `
  query ListNotUpdatedSusQueue($health_center_id: String) {
    listNotUpdatedSusQueue(health_center_id: $health_center_id) {
      id
      user_id
      user_name
      phone
      closed
      status
      current_ticket
    }
  }
`;
export const listUpdatedSusQueue = /* GraphQL */ `
  query ListUpdatedSusQueue($health_center_id: String) {
    listUpdatedSusQueue(health_center_id: $health_center_id) {
      id
      user_id
      user_name
      phone
      closed
      status
      current_ticket
    }
  }
`;
export const listClosedSusQueue = /* GraphQL */ `
  query ListClosedSusQueue($health_center_id: String) {
    listClosedSusQueue(health_center_id: $health_center_id) {
      id
      user_id
      user_name
      phone
      closed
      status
      current_ticket
    }
  }
`;
export const getHealthCenterById = /* GraphQL */ `
  query GetHealthCenterById {
    getHealthCenterById {
      id
      name
      address
      ticket_in_attendance
      user_ticket_number
      attendance_hour
      closing_atenddance_hour
      waiting_time
      image
      image_link
      ticket_today
      ticket_tomorrow
      current_ticket
      closed
      opening_date
      week_opening
      weekend_opening
      lat
      lng
      status
    }
  }
`;
export const getHealthCenterByIdDashboard = /* GraphQL */ `
  query GetHealthCenterByIdDashboard($id: String) {
    getHealthCenterByIdDashboard(id: $id) {
      id
      name
      address
      ticket_in_attendance
      user_ticket_number
      attendance_hour
      closing_atenddance_hour
      waiting_time
      image
      image_link
      ticket_today
      ticket_tomorrow
      current_ticket
      closed
      opening_date
      week_opening
      weekend_opening
      lat
      lng
      status
    }
  }
`;
export const cityEvent = /* GraphQL */ `
  query CityEvent {
    cityEvent {
      ID
      date
      title
    }
  }
`;
export const cityEventPost = /* GraphQL */ `
  query CityEventPost($ID: String) {
    cityEventPost(ID: $ID) {
      ID
      date
      hour
      title
      image
      text
      footer
      address
    }
  }
`;
export const decryptQrcode = /* GraphQL */ `
  query DecryptQrcode($qrcode: String) {
    decryptQrcode(qrcode: $qrcode)
  }
`;
export const dataSearch = /* GraphQL */ `
  query DataSearch($start_date: String, $end_date: String) {
    dataSearch(start_date: $start_date, end_date: $end_date) {
      type_name
      type_quantity
      opened_ombudsman
      opened_occurrences
      occurrences_in_attendance
      mobile_users_quantity
    }
  }
`;
export const heatMapFilter = /* GraphQL */ `
  query HeatMapFilter($start_date: String, $end_date: String, $type: String) {
    heatMapFilter(start_date: $start_date, end_date: $end_date, type: $type)
  }
`;
export const listEmergencyPhones = /* GraphQL */ `
  query ListEmergencyPhones {
    listEmergencyPhones {
      name
      number
    }
  }
`;
export const listAgentsObs = /* GraphQL */ `
  query ListAgentsObs($id: String!) {
    listAgentsObs(id: $id) {
      operator_name
      user_id
      obs
    }
  }
`;
export const listOmbudsmanTickets = /* GraphQL */ `
  query ListOmbudsmanTickets(
    $priorities: [PRIORITY]!
    $status: [String]!
    $types: [String]!
    $show: SHOW
    $last_item: String
  ) {
    listOmbudsmanTickets(
      priorities: $priorities
      status: $status
      types: $types
      show: $show
      last_item: $last_item
    ) {
      items {
        id
        protocol
        city
        is_ombudsman
        type
        subject
        description
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        last_update
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        closed
        external_protocol
        geohash
        geo_group_id
        last_protocol
        sound_alert
        date_started
        open_days
        completion_date
        date_to_close
        end_date
        date_closed
        extended
        type_id
        type_name
        office_id
        office_name
        lat
        lgt
        address
        url_image
        icon
        created_by
        user_name
        user_email
        user_phone
        cpf
        identified
      }
      last_item
      count
    }
  }
`;
export const listOmbudsmanTicketsV2 = /* GraphQL */ `
  query ListOmbudsmanTicketsV2(
    $priorities: [PRIORITY]!
    $status: [String]!
    $types: [String]!
    $show: SHOW
    $from: Int
    $size: Int
  ) {
    listOmbudsmanTicketsV2(
      priorities: $priorities
      status: $status
      types: $types
      show: $show
      from: $from
      size: $size
    ) {
      items {
        id
        protocol
        city
        is_ombudsman
        type
        subject
        description
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        last_update
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        closed
        external_protocol
        geohash
        geo_group_id
        last_protocol
        sound_alert
        date_started
        open_days
        completion_date
        date_to_close
        end_date
        date_closed
        extended
        type_id
        type_name
        office_id
        office_name
        lat
        lgt
        address
        url_image
        icon
        created_by
        user_name
        user_email
        user_phone
        cpf
        identified
      }
      count
      total
      left
    }
  }
`;
export const getOmbudsmanTicketsByProtocol = /* GraphQL */ `
  query GetOmbudsmanTicketsByProtocol($protocol: String, $show: SHOW) {
    getOmbudsmanTicketsByProtocol(protocol: $protocol, show: $show) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const getOmbudsmanTicket = /* GraphQL */ `
  query GetOmbudsmanTicket($id: ID!) {
    getOmbudsmanTicket(id: $id) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const listOpenOccurrences = /* GraphQL */ `
  query ListOpenOccurrences {
    listOpenOccurrences {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const listOccurrencesStatus = /* GraphQL */ `
  query ListOccurrencesStatus($show: SHOW_ENABLED) {
    listOccurrencesStatus(show: $show) {
      city
      name
      enabled
    }
  }
`;
export const listUserPriority = /* GraphQL */ `
  query ListUserPriority($show: SHOW_ENABLED) {
    listUserPriority(show: $show) {
      id
      city
      name
      enabled
    }
  }
`;
export const listHealthCenter = /* GraphQL */ `
  query ListHealthCenter {
    listHealthCenter {
      id
      name
      address
      ticket_in_attendance
      user_ticket_number
      attendance_hour
      closing_atenddance_hour
      waiting_time
      image
      image_link
      ticket_today
      ticket_tomorrow
      current_ticket
      closed
      opening_date
      week_opening
      weekend_opening
      lat
      lng
      status
    }
  }
`;
export const listMobileUserHealthCenterHistoric = /* GraphQL */ `
  query ListMobileUserHealthCenterHistoric {
    listMobileUserHealthCenterHistoric {
      id
      user_id
      name
      address
      status
      closed
      get_ticket_date
      attendance_date
      opening_date
      get_ticket_time
      attendance_time
      closing_attendance_time
      closing_date
      waiting_time
    }
  }
`;
export const listMobileUserHealthCenterHistoricDashboard = /* GraphQL */ `
  query ListMobileUserHealthCenterHistoricDashboard($user_id: String) {
    listMobileUserHealthCenterHistoricDashboard(user_id: $user_id) {
      id
      user_id
      name
      address
      status
      closed
      get_ticket_date
      attendance_date
      opening_date
      get_ticket_time
      attendance_time
      closing_attendance_time
      closing_date
      waiting_time
    }
  }
`;
export const listOccurrencesByTypePagination = /* GraphQL */ `
  query ListOccurrencesByTypePagination(
    $input: ListOccurrencesByType
    $last_item: AWSJSON
  ) {
    listOccurrencesByTypePagination(input: $input, last_item: $last_item) {
      items {
        id
        protocol
        city
        is_ombudsman
        type
        subject
        description
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        last_update
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        closed
        external_protocol
        geohash
        geo_group_id
        last_protocol
        sound_alert
        date_started
        open_days
        completion_date
        date_to_close
        end_date
        date_closed
        extended
        type_id
        type_name
        office_id
        office_name
        lat
        lgt
        address
        url_image
        icon
        created_by
        user_name
        user_email
        user_phone
        cpf
        identified
      }
      last_item
      count
    }
  }
`;
export const listOccurrencesByOfficePagination = /* GraphQL */ `
  query ListOccurrencesByOfficePagination(
    $input: ListOccurrencesByOffice
    $last_item: AWSJSON
  ) {
    listOccurrencesByOfficePagination(input: $input, last_item: $last_item) {
      items {
        id
        protocol
        city
        is_ombudsman
        type
        subject
        description
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        last_update
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        closed
        external_protocol
        geohash
        geo_group_id
        last_protocol
        sound_alert
        date_started
        open_days
        completion_date
        date_to_close
        end_date
        date_closed
        extended
        type_id
        type_name
        office_id
        office_name
        lat
        lgt
        address
        url_image
        icon
        created_by
        user_name
        user_email
        user_phone
        cpf
        identified
      }
      last_item
      count
    }
  }
`;
export const listAllOccurrencesCreatedBy = /* GraphQL */ `
  query ListAllOccurrencesCreatedBy(
    $user_id: String!
    $limit: Int
    $last_item: AWSJSON
  ) {
    listAllOccurrencesCreatedBy(
      user_id: $user_id
      limit: $limit
      last_item: $last_item
    ) {
      items {
        id
        protocol
        city
        is_ombudsman
        type
        subject
        description
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        last_update
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        closed
        external_protocol
        geohash
        geo_group_id
        last_protocol
        sound_alert
        date_started
        open_days
        completion_date
        date_to_close
        end_date
        date_closed
        extended
        type_id
        type_name
        office_id
        office_name
        lat
        lgt
        address
        url_image
        icon
        created_by
        user_name
        user_email
        user_phone
        cpf
        identified
      }
      last_item
      count
    }
  }
`;
export const listAllOccurrencesToMobileV2 = /* GraphQL */ `
  query ListAllOccurrencesToMobileV2($limit: Int, $last_item: AWSJSON) {
    listAllOccurrencesToMobileV2(limit: $limit, last_item: $last_item) {
      items {
        id
        protocol
        city
        is_ombudsman
        type
        subject
        description
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        last_update
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        closed
        external_protocol
        geohash
        geo_group_id
        last_protocol
        sound_alert
        date_started
        open_days
        completion_date
        date_to_close
        end_date
        date_closed
        extended
        type_id
        type_name
        office_id
        office_name
        lat
        lgt
        address
        url_image
        icon
        created_by
        user_name
        user_email
        user_phone
        cpf
        identified
      }
      last_item
      count
    }
  }
`;
export const listAllOccurrencesToMobile = /* GraphQL */ `
  query ListAllOccurrencesToMobile($status: [String]!, $show: SHOW) {
    listAllOccurrencesToMobile(status: $status, show: $show) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const listContactsToUserMobile = /* GraphQL */ `
  query ListContactsToUserMobile {
    listContactsToUserMobile {
      id
      name
      phone
      description
      order
    }
  }
`;
export const listContactsOfUserMobile = /* GraphQL */ `
  query ListContactsOfUserMobile($id: String!) {
    listContactsOfUserMobile(id: $id) {
      id
      name
      phone
      description
      order
    }
  }
`;
export const listMPUOfUserMobile = /* GraphQL */ `
  query ListMPUOfUserMobile($id: String!) {
    listMPUOfUserMobile(id: $id) {
      id
      user_id
      city
      enabled
      registration_number
      begin_date
      end_date
      obs
      suspect {
        name
        phone
        url_image
        url_image_by_mobile
        image
        city
        street
        district
        suspect_address_complement
        number
        rg
        cpf
        suspect_obs
        summons_date
        suspect_veicle
      }
    }
  }
`;
export const listMPUToUserMobile = /* GraphQL */ `
  query ListMPUToUserMobile {
    listMPUToUserMobile {
      id
      user_id
      city
      enabled
      registration_number
      begin_date
      end_date
      obs
      suspect {
        name
        phone
        url_image
        url_image_by_mobile
        image
        city
        street
        district
        suspect_address_complement
        number
        rg
        cpf
        suspect_obs
        summons_date
        suspect_veicle
      }
    }
  }
`;
export const getOccurrencesByProtocol = /* GraphQL */ `
  query GetOccurrencesByProtocol($protocol: String, $show: SHOW) {
    getOccurrencesByProtocol(protocol: $protocol, show: $show) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const getOccurrence = /* GraphQL */ `
  query GetOccurrence($id: ID!) {
    getOccurrence(id: $id) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const listNotUpdated = /* GraphQL */ `
  query ListNotUpdated($last_item: AWSJSON) {
    listNotUpdated(last_item: $last_item) {
      items {
        id
        city
        icon
        type
        type_name
        sound_alert
        is_ombudsman
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        office_id
        office_name
        last_update
        closed
        external_protocol
        protocol
        last_protocol
        created_by
        occurrences_number
        occurrences_number_open
        geohash
        lat
        lgt
        date_started
        date_closed
        date_to_close
      }
      last_item
      count
    }
  }
`;
export const listUpdated = /* GraphQL */ `
  query ListUpdated($last_item: AWSJSON) {
    listUpdated(last_item: $last_item) {
      items {
        id
        city
        icon
        type
        type_name
        sound_alert
        is_ombudsman
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        office_id
        office_name
        last_update
        closed
        external_protocol
        protocol
        last_protocol
        created_by
        occurrences_number
        occurrences_number_open
        geohash
        lat
        lgt
        date_started
        date_closed
        date_to_close
      }
      last_item
      count
    }
  }
`;
export const listLastClosed = /* GraphQL */ `
  query ListLastClosed($last_item: AWSJSON) {
    listLastClosed(last_item: $last_item) {
      items {
        id
        city
        icon
        type
        type_name
        sound_alert
        is_ombudsman
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        office_id
        office_name
        last_update
        closed
        external_protocol
        protocol
        last_protocol
        created_by
        occurrences_number
        occurrences_number_open
        geohash
        lat
        lgt
        date_started
        date_closed
        date_to_close
      }
      last_item
      count
    }
  }
`;
export const listOccurrencesOfGroup = /* GraphQL */ `
  query ListOccurrencesOfGroup($id: ID!, $last_item: AWSJSON) {
    listOccurrencesOfGroup(id: $id, last_item: $last_item) {
      items {
        id
        protocol
        city
        is_ombudsman
        type
        subject
        description
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        last_update
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        closed
        external_protocol
        geohash
        geo_group_id
        last_protocol
        sound_alert
        date_started
        open_days
        completion_date
        date_to_close
        end_date
        date_closed
        extended
        type_id
        type_name
        office_id
        office_name
        lat
        lgt
        address
        url_image
        icon
        created_by
        user_name
        user_email
        user_phone
        cpf
        identified
      }
      last_item
      count
    }
  }
`;
export const listOccurrencesGroupByType = /* GraphQL */ `
  query ListOccurrencesGroupByType(
    $input: ListOccurrencesByType
    $last_item: AWSJSON
  ) {
    listOccurrencesGroupByType(input: $input, last_item: $last_item) {
      items {
        id
        city
        icon
        type
        type_name
        sound_alert
        is_ombudsman
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        office_id
        office_name
        last_update
        closed
        external_protocol
        protocol
        last_protocol
        created_by
        occurrences_number
        occurrences_number_open
        geohash
        lat
        lgt
        date_started
        date_closed
        date_to_close
      }
      last_item
      count
    }
  }
`;
export const listOccurrencesGroupByOffice = /* GraphQL */ `
  query ListOccurrencesGroupByOffice(
    $input: ListOccurrencesByOffice
    $last_item: AWSJSON
  ) {
    listOccurrencesGroupByOffice(input: $input, last_item: $last_item) {
      items {
        id
        city
        icon
        type
        type_name
        sound_alert
        is_ombudsman
        historic {
          note
          date
          priority
          status
          external_protocol
          operator_id
          operator_name
          completion_date
          closed
          url_image
          last_protocol
          protocol
        }
        notes {
          note
          date
          public
          operator_name
          operator_id
        }
        status
        priority
        priority_number
        office_id
        office_name
        last_update
        closed
        external_protocol
        protocol
        last_protocol
        created_by
        occurrences_number
        occurrences_number_open
        geohash
        lat
        lgt
        date_started
        date_closed
        date_to_close
      }
      last_item
      count
    }
  }
`;
export const listCognitoUsers = /* GraphQL */ `
  query ListCognitoUsers {
    listCognitoUsers {
      id
      name
      phone
      email
      city
      offices {
        id
        name
        action
      }
      permissions {
        id
        name
        action
      }
      occurrences_types {
        id
        icon
        city
        name
        office_id
        office_name
        sound_alert
        group
        monitor
        priority
        integration {
          token_header
          token
          enabled
          url
          api
          body_new
          body_update
        }
      }
      occurrences_status {
        city
        name
        enabled
      }
      user_priority {
        id
        city
        name
        enabled
      }
      vaccine_status
      health_center_id
    }
  }
`;
export const getCurrentCognitoUser = /* GraphQL */ `
  query GetCurrentCognitoUser {
    getCurrentCognitoUser {
      id
      name
      phone
      email
      city
      offices {
        id
        name
        action
      }
      permissions {
        id
        name
        action
      }
      occurrences_types {
        id
        icon
        city
        name
        office_id
        office_name
        sound_alert
        group
        monitor
        priority
        integration {
          token_header
          token
          enabled
          url
          api
          body_new
          body_update
        }
      }
      occurrences_status {
        city
        name
        enabled
      }
      user_priority {
        id
        city
        name
        enabled
      }
      vaccine_status
      health_center_id
    }
  }
`;
export const getCognitoUser = /* GraphQL */ `
  query GetCognitoUser($id: ID!) {
    getCognitoUser(id: $id) {
      id
      name
      phone
      email
      city
      offices {
        id
        name
        action
      }
      permissions {
        id
        name
        action
      }
      occurrences_types {
        id
        icon
        city
        name
        office_id
        office_name
        sound_alert
        group
        monitor
        priority
        integration {
          token_header
          token
          enabled
          url
          api
          body_new
          body_update
        }
      }
      occurrences_status {
        city
        name
        enabled
      }
      user_priority {
        id
        city
        name
        enabled
      }
      vaccine_status
      health_center_id
    }
  }
`;
export const listMobileCognitoUserMPU = /* GraphQL */ `
  query ListMobileCognitoUserMPU(
    $mpu: SHOW_ENABLED!
    $fynd_by: String
    $limit: Int
    $last_item: String
  ) {
    listMobileCognitoUserMPU(
      mpu: $mpu
      fynd_by: $fynd_by
      limit: $limit
      last_item: $last_item
    ) {
      items {
        id
        city
        district
        district_name
        name
        email
        phone
        cpf
        rg
        birthdate
        street
        number
        complement
        gender
        image
        mpu
        obs
        last_access
        vaccine_priority
        url_profile_image
        url_image
        user_url_image
        url_profile_image_by_system
        login_by
      }
      last_item
      count
    }
  }
`;
export const getMobileCognitoUserByID = /* GraphQL */ `
  query GetMobileCognitoUserByID($id: ID!) {
    getMobileCognitoUserByID(id: $id) {
      id
      city
      district
      district_name
      name
      email
      phone
      cpf
      rg
      birthdate
      street
      number
      complement
      gender
      image
      mpu
      obs
      last_access
      vaccine_priority
      url_profile_image
      url_image
      user_url_image
      url_profile_image_by_system
      login_by
    }
  }
`;
export const listOffices = /* GraphQL */ `
  query ListOffices {
    listOffices {
      id
      name
      occurrences
    }
  }
`;
export const getOffice = /* GraphQL */ `
  query GetOffice($id: ID!) {
    getOffice(id: $id) {
      id
      name
      occurrences
    }
  }
`;
export const getOccurrenceType = /* GraphQL */ `
  query GetOccurrenceType($id: ID!) {
    getOccurrenceType(id: $id) {
      id
      icon
      city
      name
      office_id
      office_name
      sound_alert
      group
      monitor
      priority
      integration {
        token_header
        token
        enabled
        url
        api
        body_new
        body_update
      }
    }
  }
`;
export const listOccurrencesType = /* GraphQL */ `
  query ListOccurrencesType {
    listOccurrencesType {
      id
      city
      name
      integration {
        token_header
        token
        enabled
        url
        api
        body_new
        body_update
      }
      ... on OccurrenceType {
        icon
        office_id
        office_name
        sound_alert
        group
        monitor
        priority
      }
      ... on OmbudsmanType {
        translate {
          key
          variables {
            name
            type
            value
          }
        }
        priorities {
          name
          type
          priority
        }
      }
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions {
    listRegions {
      id
      city
      name
    }
  }
`;
export const listDistricts = /* GraphQL */ `
  query ListDistricts {
    listDistricts {
      id
      city
      name
      region
    }
  }
`;
export const listDistrictsToMobile = /* GraphQL */ `
  query ListDistrictsToMobile($city: String!) {
    listDistrictsToMobile(city: $city) {
      id
      city
      name
      region
    }
  }
`;
export const listQuizzes = /* GraphQL */ `
  query ListQuizzes {
    listQuizzes {
      id
      name
      current
      questions {
        id
        description
        note
      }
    }
  }
`;
export const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      name
      current
      questions {
        id
        description
        note
      }
    }
  }
`;
export const getCurrentQuizMobile = /* GraphQL */ `
  query GetCurrentQuizMobile {
    getCurrentQuizMobile {
      id
      name
      current
      questions {
        id
        description
        note
      }
    }
  }
`;
export const reportLastMonths = /* GraphQL */ `
  query ReportLastMonths($quiz: ID!, $months: Int!) {
    reportLastMonths(quiz: $quiz, months: $months) {
      name
      series {
        name
        value
        min
        max
      }
    }
  }
`;
export const reportQuizAnswersWithNotes = /* GraphQL */ `
  query ReportQuizAnswersWithNotes(
    $quiz: ID!
    $date_start: String!
    $date_end: String!
    $last_item: String
  ) {
    reportQuizAnswersWithNotes(
      quiz: $quiz
      date_start: $date_start
      date_end: $date_end
      last_item: $last_item
    ) {
      items {
        id
        quiz
        answers {
          id
          value
        }
        city
        date
        note
      }
      last_item
      count
    }
  }
`;
export const listSystemPages = /* GraphQL */ `
  query ListSystemPages {
    listSystemPages {
      name
      pages {
        id
        name
        desc
        status
      }
    }
  }
`;
export const getCity = /* GraphQL */ `
  query GetCity {
    getCity {
      id
      name
      state
      integrations {
        key
        name
        integration {
          token_header
          token
          enabled
          url
          api
          body_new
          body_update
        }
        translate {
          key
          variables {
            name
            type
            value
          }
        }
      }
      flag
    }
  }
`;
export const existInMobile = /* GraphQL */ `
  query ExistInMobile($city: String!, $value: String!, $find_by: LOGIN_BY!) {
    existInMobile(city: $city, value: $value, find_by: $find_by) {
      email
      phone
      registered
      confirmed
    }
  }
`;
export const listAllPointsOnMap = /* GraphQL */ `
  query ListAllPointsOnMap {
    listAllPointsOnMap {
      id
      name
      address
      address_number
      lat
      long
      type
      opening_hours {
        monday {
          am
          pm
        }
        tuesday {
          am
          pm
        }
        wednesday {
          am
          pm
        }
        thursday {
          am
          pm
        }
        friday {
          am
          pm
        }
        saturday {
          am
          pm
        }
        sunday {
          am
          pm
        }
      }
      phone
      url_img
      url_site
      vaccine_local_id
    }
  }
`;
export const listAllNewsFeed = /* GraphQL */ `
  query ListAllNewsFeed {
    listAllNewsFeed {
      id
      text
      feed_link
      image_link
    }
  }
`;
export const listAllMobileUsers = /* GraphQL */ `
  query ListAllMobileUsers {
    listAllMobileUsers {
      name
      vaccine_local_id
      vaccine_status
      vaccine_local_name
      vaccine_priority_id
    }
  }
`;
export const listOverviewByTypeOfOccurrences = /* GraphQL */ `
  query ListOverviewByTypeOfOccurrences($start: String!, $end: String!) {
    listOverviewByTypeOfOccurrences(start: $start, end: $end) {
      id
      icon
      name
      created
      closed
    }
  }
`;
export const listOverviewByTypeOfOmbudsman = /* GraphQL */ `
  query ListOverviewByTypeOfOmbudsman($start: String!, $end: String!) {
    listOverviewByTypeOfOmbudsman(start: $start, end: $end) {
      id
      icon
      name
      created
      closed
    }
  }
`;
export const getHealthCenterQueueData = /* GraphQL */ `
  query GetHealthCenterQueueData {
    getHealthCenterQueueData {
      id
      name
      address
      ticket_in_attendance
      user_ticket_number
      attendance_hour
      closing_atenddance_hour
      waiting_time
      image
      image_link
      ticket_today
      ticket_tomorrow
      current_ticket
      closed
      opening_date
      week_opening
      weekend_opening
      lat
      lng
      status
    }
  }
`;
