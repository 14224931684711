import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

  ngOnInit() {
  }

  headerForm: FormGroup = new FormGroup({});

  constructor(private formBuilder: FormBuilder,) {
    this.headerForm = this.formBuilder.group({
      field: new FormControl(null, [Validators.required]),
    })
  }

  fields = []
  forms = forms_

  getFormOptions(event: any, form: any) {
    if (event.isUserInput) {
      this.fields = form
    }
  }

  toggleEdition(id: any){
    document.getElementById(id).classList.toggle('d-none')

  }

  displayedColumns: string[] = ['name', 'action'];
}

let forms_ = [
  {
    title: 'Diário de bordo',
    fields: [
      {
        title: "Motorista",
        content: [
          "Adriano",
          "Antônio",
          "Ariovaldo",
          "Carolina",
          "Cláudio",
          "Cleiton",
          "Cristiano",
          "Eduardo Guimarães",
          "Eduardo Verdum",
          "Ernandes",
          "Evandro",
          "Fabiano",
          "Fleuri",
          "Leticia",
          "Márcio",
          "Mauricio",
          "Roberto",
          "Paulo G",
          "Paulo Verti",
          "Rogiel",
          "Rollisson",
          "Silvestre",
          "Wagner",
          "Taimir",
          "Vanderlei"
        ]
      },
      {
        title: "Veículo",
        content: [
          "IXG 2958 (ONIX)",
          "IXG 2971 (ONIX)",
          "IPP 0121 (GOL)",
          "IQX 6522 (COURRIER)",
          "ILK 2817 (S10)",
          "IOX 2230 (TORNADO)",
          "IRA 5511 (BROS)",
          "IZW 9I95 (XRE)",
          "IZW 9J10 (XRE)",
          "IZY 1D70 (XRE) 4",
          "IZY 1D71 (XRE) 3",
          "OUTRO"
        ]
      }
    ]
  },
  {
    title: 'Viária',
    fields: [
      {
        title: "Colaborador",
        content: [
          "Carlos Eugênio",
          "Leandro de Almeida",
          "Luiz Carlos",
          "Paulo Roberto"
        ]
      },
      {
        title: "Destino",
        content: [
          "Águas Claras",
          "Aparecida",
          "Área Rural",
          "Augusta",
          "Beco dos Cunhas",
          "Branquinha",
          "Caminho do Meio",
          "Cecília",
          "Centro",
          "Cocão",
          "Esmeralda",
          "Espigão",
          "Estalagem",
          "Fiúza",
          "Florescente",
          "Itapuã",
          "Krahe",
          "Martinica",
          "Jari",
          "Passo do Vigário",
          "Querência",
          "Santa Cecilia",
          "Santa Isabel",
          "Santo Onofre",
          "São Lucas",
          "São Tomé",
          "Tarumã",
          "Viamópolis",
          "Vila Augusta",
          "Vila Elza",
          "Vila Gaúcha"
        ]
      },
      {
        title: "Atividade de atendimento",
        content: [
          "Abono",
          "Concretagem",
          "Conferência de Materiais",
          "Desentortar Placas",
          "Estoque DEMAC",
          "Instalação de Tachões",
          "Instalação de Redutores",
          "Instalação de Placas",
          "Lavagem de Abrigos",
          "Limpeza de Setor",
          "Montagem de Placas",
          "Perfuração de Solos",
          "Pintura de Placas",
          "Pintura de Telhas",
          "Pintura de Abrigos",
          "Pintura de Meio fio",
          "Pintura de Lombadas",
          "Pintura de Faixas de Segurança",
          "Pintura de Balizas de Madeira",
          "Retirada de Placas",
          "Remoção de Abrigos",
          "Retirada de Bases de Abrigos",
          "Vistorias"
        ]
      },
      {
        title: "Sinalização utilizada",
        content: [
          "Balizador",
          "Cavaletes",
          "Cones",
          "Fita Zebrada",
          "Placas"
        ]
      },
    ]
  },
  {
    title: 'Transporte',
    fields: [
      {
        title: "Nome do colaborador",
        content: [
          "Alvino",
          "Anselmo",
          "Antônio",
          "Ariovaldo",
          "Carolina",
          "Maria Cristina",
          "Vanderlei"
        ]
      }, {
        title: "Atividade / Serviço",
        content: [
          "Abono",
          "Análise e Pareceres de Processos",
          "Atendimento ao Público",
          "Atualização de Normas e Legislação",
          "Cadastramento de Processos",
          "Cadastro de Motoristas (Habilitação EPTV)",
          "Cadastro e inclusão via sistema dos operadores/autorizatários/permissionários/concessionários",
          "Cálculo Tarifário",
          "Carteira de estacionamento em vaga especial",
          "Controle e instalação de abrigos",
          "Denúncias",
          "Emissão: certidão/taxas/alvarás/ofícios etc.",
          "Fechamento de roletas e hodômetros",
          "Fiscalização e operação de transportes de passageiros/cargas",
          "Monitoramento externo de transporte coletivo",
          "Monitoramento GPS transporte coletivo",
          "Monitoramento, respostas e encaminhamento de e-mails",
          "Notificações/Intimações fiscais",
          "Operações Conjuntas",
          "Pesquisas e Planejamento",
          "Planilha de Vistorias",
          "Plantão",
          "Relatórios em Geral",
          "Reuniões",
          "Tramite de Processos",
          "Vistorias externas",
        ]
      },
    ]
  },
  {
    title: 'Transporte ADM',
    fields: [
      {
        title: "Nome do colaborador",
        content: [
          "Fabiana",
          "Graziela",
          "Janaí",
          "Mayara",
          "Reginaldo",
          "Rita",
          "Tatiane",
        ]
      },
      {
        title: "Atividade / Serviço",
        content: [
          "Abertura de Processo",
          "Abono",
          "Arquivos",
          "Atendimento ao Público",
          "Autorizações",
          "Baixa de Autos de Infração",
          "Certidões",
          "CETRAN",
          "Consulta Veículos",
          "Correios",
          "Doc Operacionais",
          "Efetividade",
          "Verificação e-mails",
          "Estatísticas",
          "Inclusão Defesa/Recursos",
          "Jada",
          "Jari",
          "Lançamento Autos de Infração",
          "Leilão",
          "Livro Ata",
          "Ofícios/Memorandos",
          "Processos",
          "Relatórios DETRAN",
          "Vistorias e devoluções de documentos",
        ]
      },
    ]
  },
  {
    title: 'Semafórica',
    fields: [
      {
        title: "Colaborador",
        content: [
          "Roberto",
          "Taimir",
        ]
      },
      {
        title: "Destino",
        content: [
          "Águas Claras",
          "Aparecida",
          "Área Rural",
          "Augusta",
          "Beco dos Cunhas",
          "Branquinha",
          "Caminho do Meio",
          "Cecília",
          "Centro",
          "Cocão",
          "Esmeralda",
          "Espigão",
          "Estalagem",
          "Fiúza",
          "Florescente",
          "Itapuã",
          "Krahe",
          "Martinica",
          "Jari",
          "Passo do Vigário",
          "Querência",
          "Santa Cecilia",
          "Santa Isabel",
          "Santo Onofre",
          "São Lucas",
          "São Tomé",
          "Tarumã",
          "Viamópolis",
          "Vila Augusta",
          "Vila Elza",
          "Vila Gaúcha"
        ]
      },
      {
        title: "Atividade de atendimento",
        content: [
          "Abono",
          "Instalação de Placas Aérea",
          "Instalação de Semáforos",
          "Instalação de Rede Elétrica",
          "Manutenção de Focais",
          "Manutenção de Módulos (Lógico/potência)",
          "Troca de Focais",
          "Troca de Conjunto de Focais",
          "Troca de Controlador",
          "Troca de Módulos de Potência",
          "Troca de Módulo Lógico",
          "Vistoria de Táxi",
          "Vistoria de Escolar",
        ]
      },
      {
        title: "Sinalização utilizada",
        content: [
          "Balizador",
          "Cavaletes",
          "Cones",
          "Fita Zebrada",
          "Placas"
        ]
      }
    ]
  },
  {
    title: 'Protocolo recepção',
    fields: [
      {
        title: "Nome do colaborador",
        content: [
          "Alisson",
          "Jaqueline",
        ]
      },
      {
        title: "Atividade / Serviço",
        content: [
          "Abono",
          "Atendimento ao Público (Informações, Dúvidas e Esclarecimentos)",
          "Autorização de Estacionamento em Vagas (Área Azul)",
          "Autorização Especial para Carro de Som (AET)",
          "Devolução de Documentos (CNH, CRLV)",
          "Emissão da Credencial de Estacionamento ( Deficientes e Idosos)",
          "Processos (Análise dos Radares Fixos)",
          "Processos (Anulação ou Suspensão de Autos de Infração)",
          "Processos (Defesa/Recurso)",
          "Solicitação (Cópia de Auto de Infração)",
          "Solicitação de Sinalização (Placas, Pinturas, Semáforos)",
          "Solicitação de Autorização para Eventos (Fechamento de vias, Desvios)",
          "Taxa para Empréstimo de Materiais Diversos (Cavaletes, Cones, Balizadores)",
        ]
      },

    ]
  },
  {
    title: 'Licitação',
    fields: [
      {
        title: "Nome do colaborador",
        content: [
          "Eduardo",
          "Letícia",
        ]
      },
      {
        title: "Atividade / Serviço",
        content: [
          "Abertura de Processo",
          "Abono",
          "Análise de Documentos",
          "Análise de documentos e certidões",
          "Análise de Editais",
          "Análise de Leis e Decretos",
          "Certame Licitatório",
          "Conferência de Materiais/Equipamentos",
          "Conferência de Processos",
          "Controle de e-mails",
          "Cotações",
          "Digitalização de Processos",
          "Elaboração de Atas de Registro de Preços",
          "Encaminhamento de Processos",
          "Licitações/Dispensas",
          "Licitações/Lançamentos/Licitacon",
          "Pedidos/Ordens de Serviço",
          "Pesquisas TCE",
          "Publicações",
          "Redação de Editais",
          "Redação de Planilhas",
          "Redação de TR",
          "Reunião Comissão/Análises/Julgamentos",
        ]
      },
    ]
  },
  {
    title: 'Júridico',
    fields: [
      {
        title: "Nome do colaborador",
        content: [
          "Bruna",
          "Imar",
          "Josi"
        ]
      },
      {
        title: "Atividade / Serviço",
        content: [
          "Abono",
          "Análise Jurídica",
          "Contestações",
          "Despachos Administrativos",
          "Elaboração de Contratos",
          "Memorandos",
          "Minutas de Lei",
          "Ofícios/Respostas MP",
          "Pareceres",
          "Petição/Ação Judicial",
          "Portarias",
          "Recursos Inominados",
          "Resoluções",
        ]
      }
    ]
  },
  {
    title: 'Engenharia',
    fields: [
      {
        title: "Nome do colaborador",
        content: [
          "Paulo",
          "Sandro"
        ]
      },
      {
        title: "Atividade / Serviço",
        content: [
          "Abono",
          "Análise de Projetos EIV",
          "Análise de Projetos Privados",
          "Atendimento Técnico ao Público",
          "Despacho de Processos",
          "Estudo Técnico",
          "Medições de Serviços",
          "Ordens de Serviço",
          "Parecer Técnico",
          "Projeto de Sinalização Viária",
          "Reuniões e diligências",
          "Vistorias Técnicas"
        ]
      }
    ]
  },
  {
    title: 'Administrativo',
    fields: [
      {
        title: "Nome do colaborador",
        content: [
          "Áureo",
          "Daniela",
          "Rafael",
          "Rosana",
          "Rosele",
        ]
      },
      {
        title: "Atividade / Serviço",
        content: [
          "Abertura de Processo",
          "Abono",
          "Análise de Documentos",
          "Conciliação Bancária",
          "Conferência de Extratos Bancários",
          "Controle Ponto",
          "Controle de Férias (Quadro EPTV)",
          "Controle Semanal de horas Extras dos Servidores Quadro EPTV",
          "Elaboração de Efetividade, Controle Vale Transportes, Risco de Vida, Penosidade, Produtividade (Quadro EPTV)",
          "Emissão de Relatórios",
          "Envio de Documentos Consultoria",
          "Folha de Pagamentos",
          "Fluxo de Caixa / Movimentação",
          "Lançamento de Despesas",
          "Memorandos / Ofícios",
          "Pagamento de Contas",
          "Recebimento de Notas Fiscais",
          "Recolhimento de Impostos",
          "Relatório Contábil",
          "Relatório Fiscal",
          "Prestação de Contas Empresas",
          "Prestação de Contas (adiantamentos)",
        ]
      }
    ]
  },
]
